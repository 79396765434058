import { Controller } from 'stimulus';

import Rails from "@rails/ujs";


export default class extends Controller {
    static targets = ["query", "user"]

    connect() {
    }

    submit() {
    const value = this.queryTarget.value
    console.log("value", value)
    Rails.ajax({
        type: "GET",
        url: `/admin/users/?query=${value}`,
        success: (_data, _status, xhr) => {
        this.element.outerHTML = xhr.response
        }
    })
    }

}

